<template>
  <div>

    <div class="dingd scrollBoxCss">
      <div class="dingdItem" v-for="(item) in dingd" :key="item.id">
        <div class="img">
          <!-- 会员卡图片 -->
          <img src="https://www.zyznz-edu.cn/profile/upload/2023/XCXPic/vipCard.png" v-show="item.productType == 1" alt="" width="100%" height="100%">
          <!-- 课程图片 -->
          <img src="https://www.zyznz-edu.cn/profile/upload/2023/XCXPic/resourceOrder.png"  v-show="item.productType == 2"  alt="" width="100%" height="100%">
          <!-- 咨询图片 -->
          <img src="https://www.zyznz-edu.cn/profile/upload/2023/XCXPic/consultOrder.png"  v-show="item.productType == 3" alt="" width="100%" height="100%">
        </div>
        <div class="text">
          <span>{{ item.productTitle }}</span><br>
          <!-- <span>全平台教师均可通用，副标题（前端写死）</span><br> -->
          <span>￥{{ item.totalAmount }} </span>
          <!-- <span v-if="item.nr"> {{ item.nr }}</span> -->
        </div>
        <div class="textRight">
          <!-- <span>查看协议</span><br> -->
          <span>订单号:{{ item.orderNo }}</span><br>
          <span>{{ item.updateTime.substr(0,10) }}</span><br>
          <div v-show="item.htvalue">
            <el-button :type="item.ht" size="mini" round>{{ item.htvalue }}</el-button>

          </div>
        </div>
      </div>
      <div v-show="dingd.length == 0" style="display: flex;justify-content: center;align-items: center;margin-top:35%;font-size: 16px;">
        暂无数据
      </div>
      <!-- 分页 -->

      <!-- <div style=" margin-left: 25%;margin-top: 10%;">
        <div class="block">
          <el-pagination layout="prev, pager, next" :total="100"></el-pagination>
        </div>
      </div> -->

    </div>
  </div>
</template>

<script>
import { getUserOrder } from '@/api/pay.js'

export default {
  name: 'comzhx3',
  data() {
    return {
      op2: [
        { value: '', label: '全部' },
        { value: 'WAIT_BUYER_PAY', label: '待付款' },
        { value: 'TRADE_SUCCESS', label: '生效中' },
        { value: 'TRADE_CLOSED', label: '已过期' },
      ],
      ops2: 0,
      dingd: [],
      orderType:'TRADE_SUCCESS',  //订单状态
                  // <el-option label="待付款" value="WAIT_BUYER_PAY"></el-option>
                  // <el-option label="交易成功" value="TRADE_SUCCESS"></el-option>
                  // <el-option label="订单取消" value="CANCEL_SUCCESS"></el-option>
                  // <el-option label="退款成功" value="REFUD_SUCCESS"></el-option>
                  // <el-option label="退款关闭" value="REFUD_FAIL"></el-option>
                  // <el-option label="订单关闭" value="TRADE_CLOSED"></el-option>
      // 备注：【生效中】给后端传参为 TRADE_SUCCESS（订单成功）的状态，是否修改和后端商量（目前是没有生效中的订单的接口）。
      // 【已过期】 给后端传参为 TRADE_CLOSED（订单关闭）的状态，是否修改和后端商量。
    };
  },
  methods: {
    handclick(item) {
      // this.ops2 = e;
      this.orderType = item.value;
      this.getOrderList()
    },
    getOrderList() {
      getUserOrder({
        phoneNumber: localStorage.getItem('phonenumber'),
        state: this.orderType
      }).then(res => {

        this.dingd = res.data.map(item=>{
            let htvalue = ''
            let ht = ''
 if(item.status == 'WAIT_BUYER_PAY'){
              htvalue = '待付款'
              ht = 'warning'
            }else if (item.status == 'TRADE_SUCCESS'){
              htvalue = '生效中'
              ht = 'success'
            }else if (item.status == 'TRADE_CLOSED'){
              htvalue = '已过期'
              ht = 'info'
            }
            return {
              ...item,
              htvalue,
              ht
            }
        })
      })
    },
  },
  mounted() {
    this.getOrderList()
  }
}
</script>

<style lang="scss" scoped>
.km {
  width: 100%;
  height: 1px;
  margin-left: 1.5%;

  div {
    width: 15%;
    height: 30px;
    border-radius: 10px;
    margin: 10px;
    float: left;
    color: #a1a1a1;
    background-color: #cfcfcf;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
  }
}

.bg {
  color: #fff !important;
  background-color: #12b098 !important;
}

.dingd {
  font-family: PingFang SC;
  width: 100%;
// overflow-y: auto;
// overflow-x: hidden;
// height: 740px;
  // border: 1px solid #000000;

  .dingdItem {
    width: 100%;
    height: 90px;
    // border: 1px solid #000000;
    margin-top: 2%;

    .img {
      width: 10%;
      height: 100%;
      margin: 2px 5px;
      float: left;
    }

    .text {
      width: 40%;
      height: 100%;
      margin: 2px 5px;
      float: left;

      span {
        display: inline-block;
        margin-top: 1%;
        font-size: 17px;
        color: #000000;
      }
    }

    .text span:nth-child(3) {
      font-size: 14px;
      color: #aeaeae;
    }

    .text span:nth-child(5) {
      color: orange;
    }

    .text span:nth-child(6) {
      border-radius: 10px;
      border: 1px solid orange;
      font-size: 12px;
      padding: 1px 4px;
      color: orange;
      font-weight: 500;
    }

    .textRight {
      float: right;
      text-align: right;
      margin-right: 15px;
    }

    .textRight span,
    .textRight div {
      display: inline-block;
      margin-top: 1%;
    }

    .textRight span:nth-child(1) {
      font-size: 10px;
      color: #12b098;
      cursor: pointer;
    }

    .textRight span:nth-child(5) {
      font-size: 13px;
    }
  }
}
</style>
